import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

function Orta(props) {
    return (
        <nav className="grid grid-cols-1 justify-items-center mt-4">
            <a className="text-2xl text-bianco-farina hover:text-giallo-grano" href="/media/pdf/menu.pdf">MENU</a>
            <div className="border-giallo-grano/30 w-full border-t-2 mt-5 mb-5"></div>
            <a  href="http://maps.apple.com/?q=40.966012,14.262405" target="_blank" rel="noreferrer" className="font-portuguesa-caps w-80 text-xl text-bianco-farina hover:text-giallo-grano text-center pl-10 pr-10"><div>via San Massimo, 82</div> Orta di Atella</a>
            <div className="border-giallo-grano/30 w-full border-t-2 mt-5 mb-5"></div>
            <a href="tel:+39-081-278-6968" target="_blank" rel="noreferrer" className="font-portuguesa-caps w-80 text-xl text-bianco-farina hover:text-giallo-grano text-center pl-10 pr-10">chiamaci al +39 081 278 6968</a>
            <div className="border-giallo-grano/30 w-full border-t-2 mt-5 mb-5"></div>
            <a href="https://www.justeat.it/restaurants-mozzeca-orta-di-atella" target="_blank" rel="noreferrer" className="font-portuguesa-caps w-80 text-xl text-bianco-farina hover:text-giallo-grano text-center pl-10 pr-10">ordina su JUST EAT</a>
            <a href="https://linktr.ee/eccofasto" target="_blank" rel="noreferrer" className="font-portuguesa-caps w-80 text-xl text-bianco-farina hover:text-giallo-grano text-center pl-10 pr-10">ordina su FASTO</a>
            <div className="border-giallo-grano/30 w-full border-t-2 mt-5 mb-5"></div>
            <div className="font-portuguesa-caps w-80 text-xl text-giallo-grano text-center pl-10 pr-10">consumazione sul posto, asporto, consegna</div>
            <div className="border-giallo-grano/30 w-full border-t-2 mt-5 mb-5"></div>
            <div className="flex w-full text-4xl gap-4 justify-center">
                <a className="text-bianco-farina hover:text-giallo-grano" href="https://www.facebook.com/mozzecapaninoteca" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                <a className="text-bianco-farina hover:text-giallo-grano" href="https://instagram.com/mozzeca.official?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                <a className="text-bianco-farina hover:text-giallo-grano" href="https://wa.me/390812786968" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
            </div>
        </nav>
    );
}

export default Orta;
