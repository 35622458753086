import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tw-elements';

const root = ReactDOM.createRoot(document.getElementById('root'));

const people = Math.floor(Math.random() * (5 - 1) + 1);
let backgroundColor = () => (people === 3 || people === 4) ? 'bg-rosso-fuoco' : 'bg-verde-friariello';
let peoples = '/media/img/peoples/'+people+'.png';
document.body.classList.add(backgroundColor());


root.render(
  <React.StrictMode>
    <App people={people} backgroundColor={backgroundColor} peoples={peoples}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
