import './App.css';

import Orta from "./components/Orta";

function App(props) {


  return (
      <section id='container' className={"grid grid-cols-1 bg-cover w-full h-full justify-items-center " + props.backgroundColor()}>
          <div className="grid p-8 max-w-[300px] h-min justify-items-center">
              <img src="/media/img/logo.png" className="p-2"></img>
              <img src={props.peoples}></img>
              <Orta></Orta>
          </div>
      </section>
  );
}

export default App;
